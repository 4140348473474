import React from "react"
import { Link } from 'gatsby';
import Helmet from "react-helmet"
import unique from "../css/HomeExtra.module.css"
import Header from "../components/header/header"
import Footer from "../components/footer/footer"
import styles from "../css/Home.module.css"
import Logo from '../components/Logo'

export default function HomeLayout(props) {
  return (
    <div className={unique.grid}>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        />
      </Helmet>
      <Header />
      <div className={unique.logoGrid}>
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <div className={styles.body}>
        <div>{props.children}</div>
      </div>
      <Footer />
    </div>
  )
}