import React, { Component } from "react"
import { Link, graphql } from "gatsby"

import Layout from "../layouts/home"
import SEO from "../components/seo"

import CardSliderComponent from "../components/card/CardSliderComponent"
import styles from "../css/Home.module.css"
import "../css/Button.css"
import "../css/testimonials.css"

class Home extends Component {
  render() {
    const data = this.props.data
    const bgImage = data.wpPage.featuredImage.node.localFile.childImageSharp.fixed.src
    const wp = data.wpPage.Home.homePage
    const gradient = wp.tintImage ? 'linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)), ' : '';
    const heroTextClass = wp.paddedText ? `padded-text` : ''

    return (
      <Layout>
        <SEO title={wp.pageTitle} ogImage={bgImage} ogUrl={this.props.location.href} />
        <main className={styles.main}>
          <div style={{opacity: wp.imageOpacity / 100, backgroundImage: gradient + `url(${bgImage})` }} className={!wp.curveImage ? styles.hero : `${styles.hero} ${styles.heroCurve}`} />
          <header className={styles.heroHeader}>
            <h1 className={heroTextClass} style={{color:wp.ctaTitleColour}}>
              <span style={{backgroundColor: wp.paddedtextbg}}>{wp.ctaTitle}</span>
              </h1>
            {wp.curveImage}

            <h3 className={heroTextClass} style={{color:wp.ctaSubTitleColour}}>
            <span style={{backgroundColor: wp.paddedtextbg}}>{wp.ctaSubTitle}</span>
              </h3>
            <div className={styles.heroButtons}>


              <Link className="button button_outline mr14" to={wp.ctaLinkLeftLink}>{wp.ctaLinkLeftText}</Link>
              <Link className="button" to={wp.ctaLinkRightLink}>{wp.ctaLinkRightText}</Link>
            </div>
          </header>
          <section className={styles.firstSection}>
            {!wp.curveImage ?
              <div className="center mt30 ">
                <h2>LATEST ARTICLES</h2>
              </div>
              : null}
            <CardSliderComponent slidesToShow={4} posts={data.allWpPost.edges} />
            <div className={styles.viewAll}>
              <Link className="button" to="/blog">
                VIEW ALL
              </Link>
            </div>
          </section>
          <section className={styles.secondSection}>
            <div className="center">
              <h2>OUR SERVICES</h2>
            </div>
            <div className="flex-container space-evenly wrap p40">
              {wp.services.map((el, idx) => (
                <div className="p5" key={idx}>
                  <Link to={el.link}>
                    <img alt={el.image.altText} src={el.image.localFile.childImageSharp.fixed.src} />
                  </Link>
                </div>
              ))}
            </div>
          </section>
          <section className={styles.thirdSection}>
            <div className="center white">
              <h2>TESTIMONIALS</h2>
            </div>
            <div className="maxWidth">
            <div className="flex-container testimonials wrap">
              {wp.reviews.map((el, idx) => (
                <div className="uk-panel" key={idx}>
                  <blockquote className="testimonials">
                    <span className="quote-icon"><svg width="60" height="60" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"> <path d="M17.27,7.79 C17.27,9.45 16.97,10.43 15.99,12.02 C14.98,13.64 13,15.23 11.56,15.97 L11.1,15.08 C12.34,14.2 13.14,13.51 14.02,11.82 C14.27,11.34 14.41,10.92 14.49,10.54 C14.3,10.58 14.09,10.6 13.88,10.6 C12.06,10.6 10.59,9.12 10.59,7.3 C10.59,5.48 12.06,4 13.88,4 C15.39,4 16.67,5.02 17.05,6.42 C17.19,6.82 17.27,7.27 17.27,7.79 L17.27,7.79 Z"></path> <path d="M8.68,7.79 C8.68,9.45 8.38,10.43 7.4,12.02 C6.39,13.64 4.41,15.23 2.97,15.97 L2.51,15.08 C3.75,14.2 4.55,13.51 5.43,11.82 C5.68,11.34 5.82,10.92 5.9,10.54 C5.71,10.58 5.5,10.6 5.29,10.6 C3.47,10.6 2,9.12 2,7.3 C2,5.48 3.47,4 5.29,4 C6.8,4 8.08,5.02 8.46,6.42 C8.6,6.82 8.68,7.27 8.68,7.79 L8.68,7.79 Z"></path></svg></span>
                    <div className="quote-content">
                      <p>{el.reviewtext}</p>
                    </div>
                    <footer className="testi-footer">
                      <img width="66" height="66" src={el.image.localFile.childImageSharp.resize.src} alt={el.image.altText} />
                      <span className="quote-name">{el.name}</span>
                      <span className="quote-title">{el.country}</span>
                    </footer>
                  </blockquote>
                </div>
              ))}
            </div>
            </div>
          </section>
          <section className={styles.secondSection}>
            <div className="center">
              <h2>ORGANISATIONS WE WORK WITH</h2>
            </div>
            <div className="flex-container space-evenly wrap p40">
              {wp.workingWith.map((el, idx) => (
                <div className="p5" key={idx}>
                  <a href={el.link}>
                    <img alt={el.image.altText} src={el.image.localFile.childImageSharp.fixed.src} />
                  </a>
                </div>
              ))}
            </div>
          </section>
        </main>
      </Layout>
    )
  }
}

export default Home

// Set here the ID of the home page.
export const pageQuery = graphql`
  query {
    wpPage(slug: {eq: "index"}) {
      Home {
        homePage {
          ctaLinkLeftLink
          ctaLinkLeftText
          ctaLinkRightLink
          ctaLinkRightText
          ctaSubTitle
          ctaSubTitleColour
          ctaTitle
          ctaTitleColour
          pageTitle
          curveImage
          imageOpacity
          tintImage
          paddedText
          paddedtextbg
          services {
            link
            image {
              altText
              localFile {
                childImageSharp {
                  fixed(height: 100, quality: 100) {
                    src
                  }
                }
              }
            }
          }
          workingWith {
            link
            image {
              altText
              localFile {
                childImageSharp {
                  fixed(height: 100, quality: 100) {
                    src
                  }
                }
              }
            }
          }
          reviews {
            country
            name
            reviewtext
            image {
              altText
              localFile {
                childImageSharp {
                  resize(height: 132, width: 132) {
                    src
                  }
                }
              }
            }
          }
        }
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              fixed(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
    allWpPost(sort: {fields: [date], order: DESC}, limit: 4) {
      edges {
        node {
          title
          excerpt
          slug
          date(formatString: "DD-MMM-YY")
          featuredImage {
            node {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 310 ) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
            }
          }
        }
      }
    }

  }
  
  
`
